import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  ProgressBarBackground: Color.Surface.Base.Background,

  ProgressSectionBackground: `linear-gradient(0deg, ${Color.Surface.Base.Background}CC  , ${Color.Surface.Base.Background} 80%)`,

  TotalText: Color.BodyText,

  WidgetBackgroundImage: `
    linear-gradient(
      ${Color.Surface.Base.Background}80,
      transparent 50%
    )
  `,
};
